<template>
  <v-card flat>
    <v-container >

        <v-row justify="center">
          <p class="text-h5"> {{$t('API settings')}}</p>
        </v-row>
        
        <v-row  justify="center">
          <v-col cols="12" md="4" align="center">
            <v-checkbox
              :label="$t('Allow webservices')"
              v-model="station.setup_sent_to_fho"
              :true-value="1"
              :false-value="0"
            ></v-checkbox>
          </v-col>
        </v-row>
        <div v-show="station.setup_sent_to_fho">
        <v-row  justify="center">
          <v-col cols="12" md="4" align="center">
            <v-text-field
             outlined
             :label="$t('Username')"
             v-model="station.owner_name"
            >

            </v-text-field>

          </v-col>
        </v-row>
        <v-row  justify="center">
          <v-col cols="12" md="4" align="center">
            <v-text-field
              outlined
              dense
              v-model="station.admin_pass"
              :label="$t('Password')"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
              counter
              :type="show1 ? 'text' : 'password'"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row  justify="center">
          <v-col cols="12" md="4" align="center">
            <v-text-field
              outlined
             :label="$t('Host')"
             v-model="station.fcc_host"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row  justify="center">
          <v-col cols="12" md="4" align="center">
            <v-text-field
             outlined
             :label="$t('Server timeout')"
             v-model.number="station.soap_timeout"
             :suffix="$t('secs')"
             persistent-hint
             :hint="$t('How long to wait response from server')"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row  justify="center">
          <v-col cols="12" md="4" align="center">
            <v-text-field
             outlined
             :label="$t('Download period')"
             v-model.number="station.grace_period"
             :suffix="$t('min')"
             persistent-hint
             :hint="$t('0 -> does not send requests server')"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row  justify="center">
          <v-col cols="12" md="4" align="center">
            <v-text-field
             outlined
             :label="$t('Max. records')"
             v-model="station.max_transactions"
             persistent-hint
             :hint="$t('Maximum records in one response from server')"
            >
            </v-text-field>
          </v-col>
        </v-row>
        </div>
        <v-row  justify="center">
          <v-col cols="12" md="4" align="right">
            <v-btn
              color="#196619"
              dark
              min-width="150"
              :loading="loading"
              @click="save"
            >{{ $t("save") }}</v-btn>
          </v-col>
        </v-row>
     
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "Webservices",
  data() {
    return {
      loading: false,
      station: null,
      show1: false,
    };
  },
  methods: {
    async initialize () {
        let res = null
        try { 
          res = await this.$http.get("/system")
          this.station = res.data
        } catch (e) {
          console.log(e)
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        } 
    },
    proceedAction(act) {
      this.action = act;
      this.dialog = true;
    },
    async save() {
      let res = null;
      try {
        this.loading = true;
        if(this.station.setup_sent_to_fho === 1 ) {
          this.station.version = '11.0.0.000'
        } else {
          this.station.version = '9.0.1.001'
          this.station.grace_period = 0
        }
        res = await this.$http.post(`/system`, this.station);
        this.$emit("alertMsg", {
          type: "success",
          text: "Data saved OK",
        });
      } catch (e) {
        console.log(e);
        this.$emit("alertMsg", {
          type: "error",
          text: e.message + "\n" + res?.error,
        });
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
  created () {
      this.initialize()
    },
};
</script>

<style>
</style>